import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconShield = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52797 4.46154C3.50015 4.46154 3.47964 4.47241 3.46941 4.48219C3.46465 4.48675 3.46274 4.49033 3.46206 4.49191C3.4617 4.49274 3.46157 4.49323 3.46154 4.49343V10.0976C3.46154 13.8634 5.11682 16.3454 6.94448 17.9577C8.79092 19.5865 10.8142 20.3238 11.4754 20.5332L11.484 20.5359L11.484 20.536C11.494 20.5393 11.506 20.5393 11.516 20.536L11.5237 20.5334L11.5237 20.5335C12.1855 20.323 14.2089 19.5845 16.0554 17.955C17.8832 16.3421 19.5385 13.86 19.5385 10.0957V4.49343C19.5384 4.49323 19.5383 4.49274 19.5379 4.49191C19.5373 4.49033 19.5354 4.48675 19.5306 4.48219C19.5204 4.47241 19.4998 4.46154 19.472 4.46154H3.52797ZM2.45912 3.42607C2.7479 3.14982 3.13293 3 3.52797 3H19.472C19.8671 3 20.2521 3.14982 20.5409 3.42607C20.8308 3.70338 21 4.08659 21 4.49338V10.0957C21 14.356 19.1022 17.2156 17.0225 19.0509C14.9644 20.867 12.7292 21.6835 11.9698 21.9253C11.6651 22.0248 11.3355 22.0249 11.0308 21.9255C10.2706 21.6845 8.03556 20.8691 5.97763 19.0537C3.89775 17.219 2 14.3593 2 10.0976V4.49338C2 4.08659 2.16923 3.70338 2.45912 3.42607Z"
      fill="#192353"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.543 8.148C16.8099 8.43593 16.7832 8.87708 16.4833 9.13333L10.3999 14.3307L7.51663 11.8673C7.21671 11.6111 7.18995 11.1699 7.45688 10.882C7.72381 10.594 8.18333 10.5684 8.48326 10.8246L10.3999 12.4622L15.5166 8.09063C15.8166 7.83439 16.2761 7.86007 16.543 8.148Z"
      fill="#868FB8"
    />
  </ChkSvgWrapper>
);
