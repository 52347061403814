import { Box, Link, styled, Typography } from '@mui/material';
import { useCallback } from 'react';

import { trackEvent } from '@apps/checkout-utils';

import {
  TERMS_AND_CONDITIONS_TEST_ID,
  UK_EVENTS,
} from '../../global.constants';
import { TAC_URL } from './TermsAndConditions.constants';

export const TermsAndConditions = () => {
  const clickHandler = useCallback(() => {
    // TODO: double check with this is triggered twice
    trackEvent(UK_EVENTS.TERMS_AND_CONDITIONS_CLICK, {
      href: TAC_URL,
      route: window.location.pathname,
    });
  }, []);

  return (
    <StyledTermsAndConditionsWrapper>
      <Typography
        variant="body3"
        color="steel300"
        data-testid={TERMS_AND_CONDITIONS_TEST_ID}
      >
        By continuing you agree to the{' '}
        <StyledLink
          href={TAC_URL}
          onClick={clickHandler}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          Terms and conditions and Privacy Policies
        </StyledLink>{' '}
        of Volt and indicated connectivity provider.
      </Typography>
    </StyledTermsAndConditionsWrapper>
  );
};

const StyledTermsAndConditionsWrapper = styled(Box)({
  textAlign: 'center',

  '> span, > a': {
    display: 'inline-block',
    lineHeight: '165%',
  },
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.steel500,
  fontWeight: theme.typography.fontWeightBold,

  '&:hover': {
    textDecorationColor: theme.palette.steel500,
  },

  '&:visited': {
    color: theme.palette.steel300,
  },
}));
