import { useCallback, useEffect, useRef } from 'react';

import { useApiAbortable } from '../useApi/useApi.hook';
import { DEFAULT_INTERVAL_TIME } from './usePolling.constants';
import type { StartPollingArgs } from './usePolling.types';

/**
 *  Hook for endpoint polling
 * @param endpoint endpoint to start polling
 */
export const usePolling = <T>(endpoint: string) => {
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const { isLoading, executeRequest, reset, ...rest } =
    useApiAbortable<T>(endpoint);

  const startPolling = useCallback(
    ({
      requestOptions,
      handleResponse,
      intervalTime = DEFAULT_INTERVAL_TIME,
    }: StartPollingArgs<T> = {}) => {
      if (!intervalIdRef.current) {
        intervalIdRef.current = setInterval(() => {
          executeRequest(requestOptions)
            .then((res) => {
              handleResponse && handleResponse(res);
            })
            .catch((e) => console.error(e));
        }, intervalTime);
      }
    },
    [executeRequest]
  );

  const stopPolling = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
      reset();
    }
  }, [reset]);

  useEffect(() => {
    return stopPolling;
  }, [stopPolling]);

  return {
    startPolling,
    stopPolling,
    isLoading,
    reset,
    ...rest,
  };
};
