import { useRef } from 'react';

export const useGetJwt = () => {
  const searchParams = useRef(new URLSearchParams(window.location.search));
  const token = searchParams.current.get('auth');

  return { token };
};

export const mockJwtQueryParam = (token: string) => {
  global.window = Object.create(window);
  Object.defineProperty(window, 'location', {
    value: {
      search: `?auth=${token}`,
    },
  });
};
