import { styled } from '@mui/material';
import List from '@mui/material/List';

import {
  ChkIconBank,
  ChkIconLock,
  ChkIconReceipt,
  ChkIconShield,
  ChkListItem,
} from '@apps/checkout-components';

import { STATUSES } from './EducationalList.constants';
import type {
  EducationalListItem,
  EducationalListProps,
} from './EducationalList.types';

const EDUCATIONAL_LIST_DEFAULT_DATA: EducationalListItem[] = [
  {
    icon: <ChkIconBank />,
    primaryText: 'Select your bank',
    secondaryText: 'From the list provided',
  },
  {
    icon: <ChkIconLock />,
    primaryText: 'Log in',
    secondaryText: 'To your banking app',
  },
  {
    icon: <ChkIconShield />,
    primaryText: 'Approve your payment',
    secondaryText: 'With a single tap',
  },
];

const EDUCATIONAL_LIST_PRESELECTED_DATA: EducationalListItem[] = [
  {
    icon: <ChkIconReceipt />,
    primaryText: 'Confirm the transaction',
    secondaryText: 'Review your purchase',
  },
  ...EDUCATIONAL_LIST_DEFAULT_DATA.slice(1),
];

const getEducationalListData = (variant: string): EducationalListItem[] => {
  return variant === STATUSES.EDUCATIONAL
    ? EDUCATIONAL_LIST_DEFAULT_DATA
    : EDUCATIONAL_LIST_PRESELECTED_DATA;
};

export const EducationalList = ({ variant }: EducationalListProps) => {
  const educationalListData: EducationalListItem[] =
    getEducationalListData(variant);

  return (
    <StyledList>
      {educationalListData.map(
        ({ icon, primaryText, secondaryText }, index) => (
          <ChkListItem
            key={`onboarding-point-${index}`}
            icon={icon}
            primaryText={primaryText}
            secondaryText={secondaryText}
          />
        )
      )}
    </StyledList>
  );
};

const StyledList = styled(List)(({ theme }) => ({
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
}));
