import { type ReactNode, useEffect, useMemo } from 'react';

import { ChkPageWrapper } from '@apps/checkout-components';

import { getComponentByStatus } from '@apps/checkout-contexts';

import { X_VOLT_API_VERSION } from '../global.constants';
import type { UkStatus } from '../global.types';
import { useAppLevelMixpanelEvents } from '../hooks/useAppLevelMixpanelEvents/useAppLevelMixpanelEvents';
import { useBanksContext } from '../hooks/useBanksContext/useBanksContext';
import { useStatusContext } from '../hooks/useStatusContext/useStatusContext';
import { BankSelectionView } from '../views/BankSelectionView/BankSelectionView';
import { EducationalView } from '../views/EducationalView/EducationalView';
import { SummaryView } from '../views/SummaryView/SummaryView';

export function App() {
  const { statusPayload, requestForStatus, isLoading } = useStatusContext();
  const { requestForBanksList } = useBanksContext();
  useAppLevelMixpanelEvents();

  const STATUS_MAP = useMemo(
    () =>
      ({
        EDUCATIONAL: <EducationalView variant="EDUCATIONAL" />,
        PRESELECTED_EDUCATIONAL: (
          <EducationalView variant="PRESELECTED_EDUCATIONAL" />
        ),
        BANK_SELECTION: <BankSelectionView />,
        BANK_REDIRECT: <SummaryView />,
        SUMMARY: <SummaryView />,
        UNKNOWN: <SummaryView />,
      } satisfies Record<UkStatus, ReactNode>),
    []
  );

  const view = useMemo(() => {
    return getComponentByStatus<UkStatus>({
      status: statusPayload?.status || null,
      componentsMap: STATUS_MAP,
      isLoading,
    });
  }, [STATUS_MAP, statusPayload?.status, isLoading]);

  useEffect(() => {
    requestForStatus({
      headers: {
        'X-Volt-Api-Version': X_VOLT_API_VERSION,
      },
    });
  }, [requestForStatus]);

  useEffect(() => {
    requestForBanksList();
  }, [requestForBanksList]);

  return <ChkPageWrapper sx={{ overflow: 'auto' }}>{view}</ChkPageWrapper>;
}

export default App;
