import { useMemo } from 'react';

import { ChkDrawer } from '@apps/checkout-components';

import { useStatusContext } from '../../hooks/useStatusContext/useStatusContext';
import { useStatusPolling } from '../../hooks/useStatusPolling/useStatusPolling';
import { DrawerContent } from './DrawerContent/DrawerContent';
import { DrawerError } from './DrawerError/DrawerError';

export const SummaryDrawer = () => {
  const { error } = useStatusContext();
  const { startStatusPolling } = useStatusPolling();

  const summaryDrawerContent = useMemo(
    () =>
      error ? (
        <DrawerError
          onRetry={startStatusPolling}
          errorMessage={error.message}
        />
      ) : (
        <DrawerContent />
      ),
    [error, startStatusPolling]
  );

  return (
    <ChkDrawer open hideBackdrop>
      {summaryDrawerContent}
    </ChkDrawer>
  );
};
