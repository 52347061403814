import { useCallback } from 'react';

import { X_VOLT_API_VERSION } from '../../global.constants';
import { useStatusContext } from '../useStatusContext/useStatusContext';
import type { NewUkStatusPayload } from './useStatusPolling.types';

export const useStatusPolling = () => {
  const {
    startPolling,
    stopPolling,
    statusPayload: currentStatusPayload,
    setStatusPayload,
  } = useStatusContext();

  const updateStatusDetails = useCallback(
    (newStatusPayload: NewUkStatusPayload) => {
      if (
        newStatusPayload?.details?.redirectUrl &&
        currentStatusPayload?.status
      ) {
        if ((newStatusPayload?.status as string) === 'APPROVED') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          window.location.href = (newStatusPayload as any)?.details
            .redirectUrl as string;
          return;
        }

        setStatusPayload({
          ...newStatusPayload,
          status: currentStatusPayload.status,
        });
      }
    },
    [setStatusPayload, currentStatusPayload]
  );

  const startStatusPolling = useCallback(() => {
    startPolling({
      requestOptions: {
        headers: {
          'X-Volt-Api-Version': X_VOLT_API_VERSION,
        },
      },
      handleResponse: updateStatusDetails,
    });
  }, [startPolling, updateStatusDetails]);

  return {
    startStatusPolling,
    stopStatusPolling: stopPolling,
  };
};
