import { banksContextFactory } from '@apps/checkout-contexts';

import type { UkBank } from '../../global.types';
import { useJwtContext } from '../useJwtContext/useJwtContext';
import type { BanksContextProviderWithJwtProps } from './useBanksContext.types';

const { BanksContextProvider, useBanksContext } =
  banksContextFactory<UkBank[]>();

const BanksContextProviderWithJwt = ({
  children,
}: BanksContextProviderWithJwtProps) => {
  const { payload, isTokenError, tokenError } = useJwtContext();

  // TODO: replace the error with a proper one
  if (isTokenError) return <p>Invalid token error: {tokenError?.message}</p>;

  // TODO: replace the loader with a proper one
  if (!payload) return <p>Loading...</p>;

  return (
    <BanksContextProvider
      currency={payload.currency}
      country="GB"
      customerId={payload.customerId}
    >
      {children}
    </BanksContextProvider>
  );
};

const withBanksContextProvider = ({
  children,
}: BanksContextProviderWithJwtProps) => (
  <BanksContextProviderWithJwt>{children}</BanksContextProviderWithJwt>
);

export {
  BanksContextProviderWithJwt as BanksContextProvider,
  useBanksContext,
  withBanksContextProvider,
};
