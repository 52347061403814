import { styled } from '@mui/material';

import { ChkBankCard } from '@apps/checkout-components';

import { trackEvent } from '@apps/checkout-utils';

import { UK_EVENTS } from '../../global.constants';
import { useStatusContext } from '../../hooks/useStatusContext/useStatusContext';

export const SummaryBankCard = () => {
  const { statusPayload, setStatus } = useStatusContext();

  const { details } = statusPayload || {};
  const { bank } = details || {};

  // TODO: replace with the proper error handling
  if (!bank) return null;

  return (
    <StyledBankCard
      bankName={bank.name}
      disabled={!bank.editable}
      iconUrl={bank.assets.logo}
      onChangeBankClick={() => {
        setStatus('BANK_SELECTION');
        trackEvent(UK_EVENTS.BANK_CHANGE_CLICK);
      }}
    />
  );
};

const StyledBankCard = styled(ChkBankCard)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));
