import { Box, styled } from '@mui/material';
import { useEffect } from 'react';

import { SummaryBankCard } from '../../components/SummaryBankCard/SummaryBankCard';
import { SummaryDetails } from '../../components/SummaryDetails/SummaryDetails';
import { SummaryDrawer } from '../../components/SummaryDrawer/SummaryDrawer';
import { SummaryHeader } from '../../components/SummaryHeader/SummaryHeader';
// import { useStatusContext } from '../../hooks/useStatusContext/useStatusContext';
import { useStatusPolling } from '../../hooks/useStatusPolling/useStatusPolling';

export const SummaryView = () => {
  // const { statusPayload } = useStatusContext();
  const { startStatusPolling, stopStatusPolling } = useStatusPolling();

  useEffect(() => {
    startStatusPolling();
    return stopStatusPolling;
  }, [startStatusPolling, stopStatusPolling]);

  // useEffect(() => {
  //   if (!redirectUrl) {
  //     startStatusPolling();
  //   } else {
  //     stopStatusPolling();
  //   }
  //   return stopStatusPolling;
  // }, [stopStatusPolling, startStatusPolling, redirectUrl]);

  return (
    <SummaryContainer>
      <SummaryHeader />
      <SummaryBankCard />
      <SummaryDetails />
      <SummaryDrawer />
    </SummaryContainer>
  );
};

const SummaryContainer = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(5)}`,
  [theme.breakpoints.down('xs')]: {
    position: 'relative',
  },
  [theme.breakpoints.up('xs')]: {
    padding: `${theme.spacing(7)} ${theme.spacing(8)} ${theme.spacing(8)}`,
  },
}));
