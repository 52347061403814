export const ChkIconPencil = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M10.0744 5.62095C10.2253 5.47001 10.47 5.47001 10.6209 5.62095L12.2313 7.23135C12.3823 7.38228 12.3823 7.62699 12.2313 7.77793L7.67509 12.3342C7.61552 12.3938 7.53811 12.4322 7.45465 12.4438L5.58631 12.7017C5.33257 12.7367 5.11557 12.5197 5.1506 12.266L5.40854 10.3976C5.42006 10.3142 5.45853 10.2368 5.51811 10.1772L10.0744 5.62095Z"
      fill="#1B63CF"
    />
    <rect
      x="12.5469"
      y="3.14844"
      width="3.05043"
      height="2.07286"
      rx="0.386491"
      transform="rotate(45 12.5469 3.14844)"
      fill="#1B63CF"
    />
  </svg>
);
