import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';

import { ChkLoadingBar, theme } from '@apps/checkout-components';

import { useDetection } from '@apps/checkout-utils';

import { useStatusContext } from '../../../hooks/useStatusContext/useStatusContext';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions';
import { DrawerButton } from './DrawerButton/DrawerButton';
import { LOADING_BAR_LABELS } from './DrawerContent.constants';
import { DrawerHeader } from './DrawerHeader/DrawerHeader';
import { DrawerQrCode } from './DrawerQrCode/DrawerQrCode';

export const DrawerContent = () => {
  const { statusPayload } = useStatusContext();
  const { isMobile } = useDetection();

  const isIPad =
    navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  const { details } = statusPayload || {};
  const { redirectUrl, bank } = details || {};
  const { name = '', assets } = bank || {};
  const { icon = '' } = assets || {};

  const drawerContent = useMemo(
    () =>
      isMobile && !isIPad ? (
        <DrawerButton
          redirectUrl={redirectUrl || ''}
          bankName={name}
          iconUrl={icon}
        />
      ) : (
        <DrawerQrCode redirectUrl={redirectUrl || ''} />
      ),
    [isMobile, redirectUrl, icon, name]
  );

  if (!redirectUrl) {
    return (
      <ChkLoadingBar
        leftText={LOADING_BAR_LABELS.WAIT}
        rightText={LOADING_BAR_LABELS.CONNECTING}
      />
    );
  }

  return (
    <>
      <DrawerHeader />
      <StyledBox>
        <TermsAndConditions />
        {drawerContent}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4),
});
