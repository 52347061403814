export const BANK_NAME_AT_SELECTION_PAGE_TEST_ID =
  'bank-name-at-selection-page';
export const BANK_NAME_AT_SUMMARY_PAGE_TEST_ID = 'bank-name-at-summary-page';
export const CUSTOMER_NAME_AT_SUMMARY_PAGE_TEST_ID =
  'customer-name-at-summary-page';
export const FALLBACK_ICON_TEST_ID = 'logo-fallback-icon';
export const PAYMENT_AMOUNT_AT_SUMMARY_PAGE_TEST_ID =
  'payment-amount-at-summary-page';
export const QR_CODE_TEST_ID = 'qr-code';
export const REFERENCE_AT_SUMMARY_PAGE_TEST_ID = 'reference-at-summary-page';
