import { useMemo } from 'react';

import { type ChkSummaryProps } from '@apps/checkout-components';
import { ChkSummary } from '@apps/checkout-components';

import { useJwtContext } from '../../hooks/useJwtContext/useJwtContext';
import { formatSummaryDetails, getTotalAmount } from './SummaryDetails.utils';

export const SummaryDetails = () => {
  const { payload: jwtPayload } = useJwtContext();
  const { summaryDetails, amount } = jwtPayload || {};

  const parsedSummaryDetails = useMemo<ChkSummaryProps['summaryItems']>(() => {
    const formattedSummaryDetails = formatSummaryDetails(summaryDetails);
    const totalAmount = getTotalAmount(amount);

    return [...formattedSummaryDetails, ...totalAmount];
  }, [summaryDetails, amount]);

  return <ChkSummary summaryItems={parsedSummaryDetails} />;
};
