import { useEffect, useRef } from 'react';

import {
  getBrowserBodyFontSize,
  GoogleTranslationObserver,
  registerGlobalProperties,
  trackEvent,
} from '@apps/checkout-utils';

import {
  CHECKOUT_VERSION,
  INTEGRATION_TYPE,
  LANGUAGE,
  UK_COUNTRY_ID,
  UK_EVENTS,
} from '../../global.constants';
import { useJwtContext } from '../useJwtContext/useJwtContext';
import { useStatusContext } from '../useStatusContext/useStatusContext';

export const useAppLevelMixpanelEvents = () => {
  const { statusPayload, isLoading } = useStatusContext();
  const isInitialStatusFetched = useRef(false);
  const { payload: jwtPayload } = useJwtContext();

  useEffect(() => {
    if (isLoading && !isInitialStatusFetched.current) {
      trackEvent(UK_EVENTS.CHECKOUT_IS_LOADING);
    }
  }, [isLoading]);

  useEffect(() => {
    registerGlobalProperties({
      countryId: UK_COUNTRY_ID,
      language: LANGUAGE,
      checkoutVersion: CHECKOUT_VERSION,
      integrationType: INTEGRATION_TYPE,
      paymentId: jwtPayload?.paymentId,
      customerId: jwtPayload?.customerId,
      browserFontSize: getBrowserBodyFontSize(),
    });
  }, [jwtPayload]);

  useEffect(() => {
    const googleObserver = new GoogleTranslationObserver((language) =>
      trackEvent(UK_EVENTS.GOOGLE_LANGUAGE_CHANGE, { language })
    );

    return () => googleObserver.disconnect();
  }, []);

  useEffect(() => {
    if (!isLoading && statusPayload && !isInitialStatusFetched.current) {
      trackEvent(UK_EVENTS.PAGE_VISIT_OPEN_BANKING, {
        checkoutStatus: statusPayload.status,
      });
      isInitialStatusFetched.current = true;
    }
  }, [isLoading, statusPayload]);

  useEffect(() => {
    registerGlobalProperties({
      licence: statusPayload?.details?.licence,
      providerName: statusPayload?.details?.providerName,
      redirectUrl: statusPayload?.details?.redirectUrl,
      bankId: statusPayload?.details?.bank?.id,
      bankName: statusPayload?.details?.bank?.name,
    });
  }, [statusPayload?.details]);
};
