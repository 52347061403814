import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  API_ENDPOINTS,
  captureException,
  setSentryContext,
  useApi,
  usePolling,
} from '@apps/checkout-utils';

import type {
  Status,
  StatusContextData,
  StatusContextProviderProps,
} from './status.types';

/**
 * This factory returns `StatusContextProvider`, `useStatusContext` and `withStatusContextProvider`;
 * Usage:
 * ````
 *   import { statusContextFactory } from '@apps/checkout-contexts';
 *   const { StatusContextProvider, useStatusContext, withStatusContextProvider } = statusContextFactory<'status1' | 'status1'>();
 *
 *   export { StatusContextProvider, useStatusContext, withStatusContextProvider };
 * ````
 */
const statusContextFactory = <T extends Status>() => {
  const StatusContext = createContext<StatusContextData<T> | null>(null);

  const useStatusContext = () => {
    const context = useContext(StatusContext);

    if (!context) {
      throw new Error(
        'useStatusContext must be used within a <StatusContextProvider>'
      );
    }

    return context;
  };

  const StatusContextProvider = ({
    paymentRequestId,
    children,
  }: StatusContextProviderProps) => {
    const [statusPayload, setStatusPayload] =
      useState<StatusContextData<T>['statusPayload']>(null);

    const GET_STATUS_ENDPOINT = useMemo(
      () => API_ENDPOINTS.getStatus(paymentRequestId || ''),
      [paymentRequestId]
    );

    const { isLoading, executeRequest, error } = useApi<T>(GET_STATUS_ENDPOINT);

    const {
      startPolling,
      stopPolling,
      error: pollingError,
    } = usePolling<T>(GET_STATUS_ENDPOINT);

    const requestForStatus = useCallback(
      async (options?: RequestInit) => {
        await executeRequest(options)
          .then((res) => {
            if (res) {
              setStatusPayload(res);
            }
          })
          .catch((e) => captureException(e));
      },
      [executeRequest]
    );

    const setStatus = useCallback(
      (status: T['status']) => {
        setStatusPayload({ ...statusPayload, status } as T);
      },
      [statusPayload]
    );

    useEffect(() => {
      setSentryContext({ key: 'status', val: statusPayload });
    }, [statusPayload]);

    return (
      <StatusContext.Provider
        value={{
          statusPayload,
          isLoading,
          error: error || pollingError || null,
          setStatusPayload,
          requestForStatus,
          setStatus,
          startPolling,
          stopPolling,
        }}
      >
        {children}
      </StatusContext.Provider>
    );
  };

  /**
   * helper to be used when wrapping a component with StatusContextProvider is needed, out of JSX structures eg. in functions
   */
  const withStatusContextProvider = ({
    children,
    ...props
  }: StatusContextProviderProps) => (
    <StatusContextProvider {...props}>{children}</StatusContextProvider>
  );

  return {
    StatusContextProvider,
    useStatusContext,
    withStatusContextProvider,
  };
};

export { statusContextFactory };
