import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconLock = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58182 9.00569C4.56477 9.00569 4.53791 9.02177 4.53791 9.05777V19.5578C4.53791 19.5938 4.56477 19.6099 4.58182 19.6099H19.4182C19.4352 19.6099 19.4621 19.5938 19.4621 19.5578V9.05777C19.4621 9.02177 19.4352 9.00569 19.4182 9.00569H4.58182ZM3.14209 9.05777C3.14209 8.26535 3.77948 7.60986 4.58182 7.60986H19.4182C20.2205 7.60986 20.8579 8.26535 20.8579 9.05777V19.5578C20.8579 20.3502 20.2205 21.0057 19.4182 21.0057H4.58182C3.77948 21.0057 3.14209 20.3502 3.14209 19.5578V9.05777Z"
      fill="#192353"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.24 14.0823C11.9366 14.0823 11.7379 14.312 11.7379 14.5383C11.7379 14.7645 11.9366 14.9942 12.24 14.9942C12.5433 14.9942 12.742 14.7645 12.742 14.5383C12.742 14.312 12.5433 14.0823 12.24 14.0823ZM10.342 14.5383C10.342 13.49 11.2178 12.6865 12.24 12.6865C13.2621 12.6865 14.1379 13.49 14.1379 14.5383C14.1379 15.5865 13.2621 16.39 12.24 16.39C11.2178 16.39 10.342 15.5865 10.342 14.5383Z"
      fill="#868FB8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 2.56598C11.1574 2.56598 10.3583 2.87932 9.77634 3.42336C9.19601 3.96586 8.87994 4.69087 8.87994 5.43572V8.30752H7.43994V5.43572C7.43994 4.27645 7.93301 3.17533 8.79296 2.37142C9.65127 1.56905 10.8056 1.12598 11.9999 1.12598C13.1943 1.12598 14.3486 1.56905 15.2069 2.37142C16.0669 3.17533 16.5599 4.27645 16.5599 5.43572V8.30752H15.1199V5.43572C15.1199 4.69087 14.8039 3.96586 14.2235 3.42336C13.6416 2.87932 12.8425 2.56598 11.9999 2.56598Z"
      fill="#192353"
    />
  </ChkSvgWrapper>
);
