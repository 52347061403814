import { Box, styled } from '@mui/material';
import { useCallback } from 'react';

import { ChkButton, ChkHeader } from '@apps/checkout-components';

import { trackEvent } from '@apps/checkout-utils';

import OnboardingImage from '../../assets/images/onboarding.png';
import { EducationalList } from '../../components/EducationalList/EducationalList';
import { TermsAndConditions } from '../../components/TermsAndConditions/TermsAndConditions';
import {
  CONTINUE_ON_EDUCATIONAL_SCREEN_BUTTON_TEST_ID,
  UK_EVENTS,
} from '../../global.constants';
import { useStatusContext } from '../../hooks/useStatusContext/useStatusContext';
import { CTA_TEXT, HEADER_TEXT } from './EducationalView.constants';
import type { EducationalViewProps } from './EducationalView.types';

export const EducationalView = ({ variant }: EducationalViewProps) => {
  const { statusPayload, setStatus } = useStatusContext();

  const ctaClickHandler = useCallback(() => {
    if (!statusPayload) return;

    trackEvent(UK_EVENTS.CONTINUE_ON_THE_WELCOME_SCREEN_CLICK);

    // ROUTING LOGIC
    if (variant === 'EDUCATIONAL') {
      setStatus('BANK_SELECTION');
    } else if (variant === 'PRESELECTED_EDUCATIONAL') {
      setStatus('SUMMARY');
    }
  }, [statusPayload, variant, setStatus]);

  return (
    <EducationalContainer>
      <ChkHeader>{HEADER_TEXT}</ChkHeader>
      <ImgContainer>
        <Img src={OnboardingImage} alt="onboarding" />
      </ImgContainer>
      <EducationalList variant={variant} />
      <TermsAndConditions />
      <ButtonContainer>
        <ChkButton
          variant="contained"
          onClick={ctaClickHandler}
          data-testid={CONTINUE_ON_EDUCATIONAL_SCREEN_BUTTON_TEST_ID}
        >
          {CTA_TEXT}
        </ChkButton>
      </ButtonContainer>
    </EducationalContainer>
  );
};

const EducationalContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(6)}`,

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(7),
  },
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
}));

const Img = styled('img')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '11.2rem',
  height: '11.2rem',

  [theme.breakpoints.up('sm')]: {
    width: '22.4rem',
    height: '22.4rem',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(5),
  gap: theme.spacing(5),

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
}));
