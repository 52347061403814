import { Box, styled } from '@mui/material';

import { BanksFilter } from '../../components/BankSelectionView/BanksFilter/BanksFilter';
import { Header } from '../../components/BankSelectionView/Header/Header';

export const BankSelectionView = () => {
  return (
    <StyledPageContainer>
      <Header />
      <BanksFilter />
    </StyledPageContainer>
  );
};

const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: [0, theme.spacing(6), theme.spacing(6)].join(' '),

  [theme.breakpoints.up('xs')]: {
    padding: [theme.spacing(6), theme.spacing(8)].join(' '),
  },
}));
