import { CssBaseline, ThemeProvider } from '@mui/material';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { theme } from '@apps/checkout-components';

import {
  getPaymentRequestId,
  initErrorTracking,
  initMixpanel,
} from '@apps/checkout-utils';

import { SENTRY_DSN } from '@chk/env-variables';

import App from './app/app';
import { BanksContextProvider } from './hooks/useBanksContext/useBanksContext';
import { JwtContextProvider } from './hooks/useJwtContext/useJwtContext';
import { StatusContextProvider } from './hooks/useStatusContext/useStatusContext';

initErrorTracking({ dsn: SENTRY_DSN });
initMixpanel();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <JwtContextProvider>
        <BanksContextProvider>
          <StatusContextProvider paymentRequestId={getPaymentRequestId()}>
            <App />
          </StatusContextProvider>
        </BanksContextProvider>
      </JwtContextProvider>
    </ThemeProvider>
  </StrictMode>
);
