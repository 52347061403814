import { Box, styled, Typography } from '@mui/material';

import { ChkButton } from '@apps/checkout-components';

import {
  ERROR_BUTTON_LABEL,
  ERROR_HEADER_LABEL,
} from './DrawerError.constants';
import type { DrawerErrorProps } from './DrawerError.types';

export const DrawerError = ({ errorMessage, onRetry }: DrawerErrorProps) => {
  return (
    <StyledBox>
      <ErrorHeader variant="body1">{ERROR_HEADER_LABEL}</ErrorHeader>
      <ErrorMessage variant="body2">{errorMessage}</ErrorMessage>
      <StyledChkButton onClick={onRetry} variant="contained">
        {ERROR_BUTTON_LABEL}
      </StyledChkButton>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
}));

const ErrorHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.navy500,
  fontWeight: theme.typography.fontWeightBold,
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.steel300,
}));

const StyledChkButton = styled(ChkButton)(({ theme }) => ({
  width: '100%',
  fontWeight: theme.typography.fontWeightBold,
}));
