import { Box, Link, styled, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';

import { ChkQrCode } from '@apps/checkout-components';

import { COMMON_EVENTS, trackEvent, useDetection } from '@apps/checkout-utils';

import {
  CONTINUE_AT_BROWSER_LINK_TEST_ID,
  QR_TEXT_TEST_ID,
  UK_EVENTS,
} from '../../../../global.constants';
import { addParamToUrl } from '../../../../utils/index.utils';
import { QR_CONFIGURATION, QR_URL_PARAMETER } from './DrawerQrCode.constants';
import type { DrawerQrCodeProps } from './DrawerQrCode.types';

export const DrawerQrCode = ({ redirectUrl }: DrawerQrCodeProps) => {
  const { isMobile } = useDetection();

  const redirectUrlWithQrParam = useMemo(
    () =>
      addParamToUrl({
        passedUrl: redirectUrl,
        paramName: QR_URL_PARAMETER,
        paramValue: '',
      }),
    [redirectUrl]
  );

  const clickHandler = useCallback(
    () =>
      trackEvent(COMMON_EVENTS.PAY_AT_MY_BANK_CLICK, {
        user_view: isMobile ? 'mobile' : 'desktop',
      }),
    [isMobile]
  );

  useEffect(() => {
    trackEvent(UK_EVENTS.QR_CODE_DISPLAYED);
  }, []);

  return (
    <StyledBox>
      <StyledChkQrCode url={redirectUrlWithQrParam} config={QR_CONFIGURATION} />

      <StyledTypography data-testid={QR_TEXT_TEST_ID} variant="body3">
        Scan with your mobile camera, or{' '}
        <StyledLink
          href={redirectUrl}
          onClick={clickHandler}
          data-testid={CONTINUE_AT_BROWSER_LINK_TEST_ID}
        >
          continue on your browser
        </StyledLink>
      </StyledTypography>
    </StyledBox>
  );
};

const StyledChkQrCode = styled(ChkQrCode)(() => ({
  width: '12rem',
  height: '12rem',
  canvas: {
    width: '100%',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.steel300,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.steel500,
  fontWeight: theme.typography.fontWeightBold,
  textDecoration: 'none',

  '&:hover': {
    textDecorationColor: theme.palette.steel500,
    textDecoration: 'underline',
  },

  '&:visited': {
    color: theme.palette.steel300,
  },
}));
