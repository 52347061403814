import { jwtDecode } from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { setSentryContext } from '@apps/checkout-utils';

import type { JwtContextData } from './jwt.types';
import { useGetJwt } from './jwt.utils';

/**
 * This factory returns `JwtContextProvider`, `useJwtContext` and `withJwtContextProvider`;
 * Usage:
 * ````
 *   import { jwtContextFactory } from '@apps/checkout-contexts';
 *   const { JwtContextProvider, useJwtContext, withJwtContextProvider } = jwtContextFactory<YOUR_JWT_HERE>();
 *
 *  export { JwtContextProvider, useJwtContext, withJwtContextProvider };
 * ````
 */
const jwtContextFactory = <T,>() => {
  const JwtContext = createContext<JwtContextData<T> | null>(null);

  const useJwtContext = () => {
    const context = useContext(JwtContext);

    if (!context) {
      throw new Error(
        'useJwtContext must be used within a <JwtContextProvider>'
      );
    }

    return context;
  };

  const JwtContextProvider = ({ children }: { children: ReactNode }) => {
    const { token } = useGetJwt();
    const [payload, setPayload] = useState<T | null>(null);
    const [tokenError, setTokenError] = useState<Error | null>(null);

    const clearTokenError = () => {
      setTokenError(null);
    };

    useEffect(() => {
      try {
        if (!token) {
          throw new Error('Invalid token');
        }
        const payload = jwtDecode<T>(token);
        setPayload(payload);
      } catch (error) {
        setTokenError(error as Error);
        setPayload(null);
      }
    }, [token]);

    useEffect(() => {
      setSentryContext({ key: 'jwt', val: payload });
    }, [payload]);

    return (
      <JwtContext.Provider
        value={{
          payload,
          isTokenError: Boolean(tokenError),
          tokenError,
          clearTokenError,
        }}
      >
        {children}
      </JwtContext.Provider>
    );
  };

  /**
   * helper to be used when wrapping a component with JwtContextProvider is needed, out of JSX structures eg. in functions
   */
  const withJwtContextProvider = ({ children }: { children: ReactNode }) => (
    <JwtContextProvider>{children}</JwtContextProvider>
  );

  return {
    JwtContextProvider,
    useJwtContext,
    withJwtContextProvider,
  };
};

export { jwtContextFactory };
