import { Box, styled, Typography } from '@mui/material';

import { theme } from '@apps/checkout-components';

import { useJwtContext } from '../../../../hooks/useJwtContext/useJwtContext';
import { useStatusContext } from '../../../../hooks/useStatusContext/useStatusContext';
import { toCapitalCase } from '../../../../utils/index.utils';

export const DrawerHeader = () => {
  const { payload: jwtPayload } = useJwtContext();
  const { statusPayload } = useStatusContext();

  const { summaryDetails, branding } = jwtPayload || {};
  const merchantName =
    summaryDetails && summaryDetails.find((row) => row.k === 'To')?.v;

  const { logo } = branding || {};
  const { details: { licence = null } = {} } = statusPayload || {};

  return (
    <StyledBox>
      <Box>
        {Boolean(merchantName) && (
          <Typography variant="body2" color={theme.palette.steel500}>
            You're paying {merchantName}
          </Typography>
        )}
        {Boolean(licence) && (
          <Typography variant="body2" color={theme.palette.steel300}>
            Processed with {toCapitalCase(licence!)}
          </Typography>
        )}
      </Box>
      {Boolean(logo) && <StyledImg src={logo} alt={`${merchantName} logo`} />}
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(5),
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledImg = styled('img')(() => ({
  maxWidth: '8.1rem',
  height: 'fit-content',
}));
