export const X_VOLT_API_VERSION = '4';

export const UK_EVENTS = {
  BANK_CHANGE_CLICK: 'Bank change click',
  BANK_SEARCHED: 'Bank name searched',
  BANK_SELECT: 'Bank select',
  CHECKOUT_IS_LOADING: 'Checkout is loading',
  CONTINUE_ON_THE_WELCOME_SCREEN_CLICK: 'Continue on the Welcome screen click',
  GOOGLE_LANGUAGE_CHANGE: 'Google translate used on checkout',
  PAGE_VISIT_OPEN_BANKING: 'Open banking page visit',
  QR_CODE_DISPLAYED: 'QR code displayed',
  TERMS_AND_CONDITIONS_CLICK: 'Terms and conditions click',
} as const;

export const CHECKOUT_VERSION = '3.0';
export const INTEGRATION_TYPE = 'hosted';
export const UK_COUNTRY_ID = 'GB';
export const UK_CURRENCY_CODE = 'GBP';
export const LANGUAGE = 'en';

export const CLOSE_ICON_TEST_ID = 'close-icon';
export const CONTINUE_AT_BROWSER_LINK_TEST_ID = 'continue-at-browser-link';
export const CONTINUE_ON_EDUCATIONAL_SCREEN_BUTTON_TEST_ID =
  'continue-on-educational-screen-button';
export const INFO_ICON_TEST_ID = 'info-icon';
export const QR_TEXT_TEST_ID = 'qr-text';
export const QR_CODE_TEST_ID = 'qr-code';
export const PAY_WITH_BUTTON_TEST_ID = 'pay-with-button';
export const SEARCH_BANK_INPUT_TEST_ID = 'search-bank-input';
export const TERMS_AND_CONDITIONS_TEST_ID = 'terms-and-conditions';
