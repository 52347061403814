import type { ChkIconProps } from './ChkIcon.types';
import { ChkSvgWrapper } from './ChkIcon.utils';

export const ChkIconReceipt = (iconProps: ChkIconProps) => (
  <ChkSvgWrapper {...iconProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7798 5.75363C4.76733 5.76726 4.75 5.79759 4.75 5.845V18.7647L6.65399 17.7746C6.88939 17.6522 7.17194 17.6633 7.39704 17.8037L9.53383 19.1371L12.154 17.7746C12.3709 17.6618 12.6291 17.6618 12.846 17.7746L15.4662 19.1371L17.603 17.8037C17.8281 17.6633 18.1106 17.6522 18.346 17.7746L20.25 18.7647V5.845C20.25 5.79759 20.2327 5.76726 20.2202 5.75363C20.2188 5.75215 20.2176 5.75095 20.2166 5.75H4.7834C4.78236 5.75095 4.78115 5.75215 4.7798 5.75363ZM3.25 5.845C3.25 5.02863 3.87004 4.25 4.77273 4.25H20.2273C21.13 4.25 21.75 5.02863 21.75 5.845V20C21.75 20.2621 21.6132 20.5051 21.3892 20.6411C21.1652 20.7771 20.8865 20.7863 20.654 20.6654L18.0338 19.3029L15.897 20.6363C15.6719 20.7767 15.3894 20.7878 15.154 20.6654L12.5 19.2853L9.84601 20.6654C9.61061 20.7878 9.32805 20.7767 9.10296 20.6363L6.96617 19.3029L4.34601 20.6654C4.11352 20.7863 3.83478 20.7771 3.61078 20.6411C3.38678 20.5051 3.25 20.2621 3.25 20V5.845Z"
      fill="#192353"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 9C7.25 8.58579 7.58579 8.25 8 8.25L17 8.25C17.4142 8.25 17.75 8.58579 17.75 9C17.75 9.41421 17.4142 9.75 17 9.75L8 9.75C7.58579 9.75 7.25 9.41421 7.25 9Z"
      fill="#868FB8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 13C7.25 12.5858 7.58579 12.25 8 12.25L17 12.25C17.4142 12.25 17.75 12.5858 17.75 13C17.75 13.4142 17.4142 13.75 17 13.75L8 13.75C7.58579 13.75 7.25 13.4142 7.25 13Z"
      fill="#868FB8"
    />
  </ChkSvgWrapper>
);
