import { Box, Link, styled } from '@mui/material';
import { useCallback } from 'react';

import { COMMON_EVENTS, trackEvent, useDetection } from '@apps/checkout-utils';

import { PAY_WITH_BUTTON_TEST_ID } from '../../../../global.constants';
import type { DrawerButtonProps } from './DrawerButton.types';

export const DrawerButton = ({
  redirectUrl,
  bankName,
  iconUrl,
}: DrawerButtonProps) => {
  const { isMobile } = useDetection();

  const clickHandler = useCallback(() => {
    trackEvent(COMMON_EVENTS.PAY_AT_MY_BANK_CLICK, {
      user_view: isMobile ? 'mobile' : 'desktop',
    });
  }, [isMobile]);

  return (
    <StyledBox>
      <StyledLink
        href={redirectUrl}
        onClick={clickHandler}
        data-testid={PAY_WITH_BUTTON_TEST_ID}
      >
        Pay with
        {Boolean(iconUrl) && (
          <StyledIcon src={iconUrl} alt={`${bankName} icon`} />
        )}
      </StyledLink>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

// TODO: add a Link variant in ChkButton component or create ChkLink
const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(5),
  border: theme.borders.borderWidth1,
  borderRadius: theme.borders.borderRadius2,
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightBold,
  cursor: 'pointer',
  transition: `
      background-color 150ms ease-in,
      border-color 150ms ease-in,
      color 150ms ease-in
    `,
  color: theme.palette.white,
  backgroundColor: theme.palette.navy500,
  '&:hover': {
    backgroundColor: theme.palette.navy400,
    borderColor: theme.palette.navy100,
  },
  '&:focus': {
    backgroundColor: theme.palette.navy400,
    borderColor: theme.palette.navy500,
  },
  textDecoration: 'none',
  width: '100%',
}));

const StyledIcon = styled('img')(({ theme }) => ({
  marginLeft: theme.spacing(3),
  width: theme.spacing(7),
  height: theme.spacing(7),
}));
