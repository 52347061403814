import { Box } from '@mui/material';
import QRCodeStyling from 'qr-code-styling';
import { useEffect, useRef } from 'react';

import { QR_CODE_TEST_ID } from '../../global.constants';
import { DEFAULT_QR_CODE_CONFIG } from './ChkQrCode.constants';
import type { ChkQrCodeProps } from './ChkQrCode.types';

export const ChkQrCode = ({ url, config, className }: ChkQrCodeProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const qrCode = useRef(
    new QRCodeStyling({
      ...DEFAULT_QR_CODE_CONFIG,
    })
  );

  useEffect(() => {
    if (wrapperRef.current) {
      qrCode.current.append(wrapperRef.current);
    }
  }, []);

  useEffect(() => {
    qrCode.current.update({ ...config, data: url });
  }, [config, url]);

  return (
    <Box className={className} data-testid={QR_CODE_TEST_ID} ref={wrapperRef} />
  );
};
